import React from 'react'
import Index from './Index';

export default function BadRequest() {
    return (
        <>
            <Index
                title="Oops, Something’s Not Right!"
                page="400"
                description="It seems we’ve run into a problem. Nothing to worry about though! We've identified the issues and have a solution to help you get back on track."
                showBackbutton={true}/>
        </>
    )
}