import React, { useState, useEffect } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import Ripples from "react-ripples";

const SingleProjectComponent = ({ singleArticles }) => {
    const [modals, setModals] = useState([]);

    const isLightColor = (hexColor) => {
        // Ambil nilai RGB dari warna
        const rgb = parseInt(hexColor.slice(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >> 8) & 0xff;
        const b = (rgb >> 0) & 0xff;

        // Hitung kecerahan berdasarkan formula W3C
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;

        // Jika kecerahan lebih dari 128, berarti warna cerah
        return brightness > 128;
    };

    const handleCopy = (color) => {
        const newModal = { id: Date.now(), color, isClosing: false };
        setModals((prevModals) => [...prevModals, newModal]);
    };

    useEffect(() => {
        const timeouts = modals.map((modal, index) => {
            return setTimeout(() => {
                setModals((prevModals) =>
                    prevModals.map((m) =>
                        m.id === modal.id ? { ...m, isClosing: true } : m
                    )
                );
                setTimeout(() => {
                    setModals((prevModals) =>
                        prevModals.filter((m) => m.id !== modal.id)
                    );
                }, 300);
            }, 2000 + index * 500); // Delay each modal by 500ms
        });

        return () => timeouts.forEach(clearTimeout);
    }, [modals]);

    return (
        <div className="flex flex-wrap justify-between gap-20 px-5 md:px-10 lg:px-32 xl:px-80">
            <div className="flex flex-col gap-5">
                <p className="outfit text-gray-500">Typography</p>
                <p className="outfit-medium">
                    {singleArticles.fields.fontname}
                </p>
            </div>
            <div className="flex flex-col gap-5">
                <p className="outfit text-gray-500">
                    Color Pallette
                </p>
                {singleArticles.fields.colorPalette && (
                    <div className="flex flex-wrap gap-5 max-w-[520px]">
                        {singleArticles.fields.colorPalette.map((Color, i) => (
                            <div
                                key={i}
                                className="flex flex-col items-center gap-3"
                            >
                                <CopyToClipboard
                                    text={Color}
                                    onCopy={() => handleCopy(Color)}
                                >
                                    <Ripples className="flex justify-center duration-300 uppercase rounded-full cursor-pointer">
                                        <div
                                            className="w-[70px] h-[70px] rounded-full"
                                            style={{
                                                background: `linear-gradient(to top left, ${Color}80, ${Color})`,
                                                color: isLightColor(Color)
                                                    ? "black"
                                                    : "white",
                                            }}
                                        ></div>
                                    </Ripples>
                                </CopyToClipboard>
                                <p className="text-[12px] outfit-medium">
                                    {Color}
                                </p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className="fixed top-0 right-0 z-50 mt-4 mr-4 flex flex-col items-end">
                {modals.map((modal, index) => (
                    <div
                        key={modal.id}
                        className={`bg-white rounded-xl shadow-lg shadow-black/5 px-4 py-2.5 transform transition-all duration-300 ease-in-out mb-2 ${
                            modal.isClosing
                                ? "animate-slideOutToRight"
                                : "animate-slideInFromRight"
                        }`}
                        style={{
                            transitionDelay: `${index * 100}ms`,
                        }}
                    >
                        <p className="text-gray-800 outfit-medium text-[15px]">
                            Done! {modal.color}’s in your hands!
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SingleProjectComponent;
