import React, { useEffect, useState } from "react";
import client from "../../../client";

function AlsoInteristing({ alsoInteristing }) {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const response = await client.getEntries({
                    content_type: "uniqueCode",
                });
                setArticles(
                    response.items.sort((a, b) =>
                        new Date(a.fields.date) > new Date(b.fields.date)
                            ? -1
                            : 1
                    )
                );
            } catch (error) {
                console.error("Failed to fetch articles:", error);
            }
        };

        fetchArticles();
    }, []);

    return (
        <>
            <div className="flex flex-col gap-5">
                <p className="outfit-semibold text-slate-900 capitalize text-[1.75rem]">
                    Varied Topics
                </p>
                {articles.map((article, i) =>
                    article.fields.title !== alsoInteristing ? (
                        <a
                            href={`/insight/${article.fields.slug}`}
                            key={i}
                            className="flex flex-col gap-2 hover:bg-amber-100 rounded-xl ml-[-0.75rem] p-3 cursor-pointer outline-none noSelect duration-100 w-full lg:w-max"
                        >
                            <span className="outfit-medium text-slate-900 dark:text-slate-100 text-[0.9rem] sm:text-[1.1rem] lg:text-[1.25rem]">
                                {article.fields.title}
                            </span>
                            <div className="flex flex-wrap gap-x-3 gap-y-2 whitespace-nowrap">
                                {article.fields.tags
                                    .slice(0, 3)
                                    .map((tag, i) => (
                                        <p
                                            key={i}
                                            className="outfit text-gray-500"
                                        >
                                            {tag}
                                        </p>
                                    ))}
                            </div>
                        </a>
                    ) : null
                )}
            </div>
        </>
    );
}

export default AlsoInteristing;
