import React from "react";
import { PaperPlaneRight } from "phosphor-react";

function Index(props) {
    const handleClick = () => {
        window.history.back();
    };

    return (
        <>
            <div
                className={`h-screen overflow-hidden relative duration-300 bg-gradient-to-b ${
                    props.page === "404"
                        ? "from-slate-200 to-gray-700"
                        : props.page === "400"
                        ? "from-red-200 to-yellow-500"
                        : props.page === "offline"
                        ? "from-blue-200 to-slate-700"
                        : props.page === "undermaintenance"
                        ? "from-orange-200 to-orange-700"
                        : "bg-red-500"
                }`}
            >
                <div className="flex flex-col items-center gap-10 text-center py-44 px-10 tracking-wider">
                    <div className="flex flex-col items-center gap-5">
                        <p className="outfit-semibold text-[31px] md:text-[39px] text-black duration-300">
                            {props.title}
                        </p>
                        <p className="outfit text-[15px] leading-7 text-black duration-300 w-full sm:w-3/4">
                            {props.description}
                        </p>
                    </div>
                    {props.showBackbutton && (
                        <div
                            onClick={handleClick}
                            className="group relative flex items-center justify-center bg-black text-white outfit text-[14px] w-[150px] py-3 rounded-xl cursor-pointer duration-200"
                        >
                            <div className="absolute top-1/2 -translate-y-1/2 left-2 group-hover:left-[7.5rem] opacity-0 group-hover:opacity-100 duration-200 ease-out">
                                <PaperPlaneRight size={18} weight="fill" />
                            </div>
                            <span className="group-hover:opacity-0 duration-200">
                                Go back
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default Index;
