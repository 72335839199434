import ReactMarkdown from 'react-markdown';
import CodeBlock from './CodeBlock';

export default function Markdown({ children }) {
    return (
        <ReactMarkdown components={{
            code: CodeBlock,
        }}>
            {children}
        </ReactMarkdown>
    );
}