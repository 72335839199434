import React, { useEffect } from "react";
import logo from "../../asset/loading.svg";

const LoadingPage = () => {
    useEffect(() => {
        const logoElement = document.querySelector('.logo-animation');
        let angle = 0;
        const maxAngle = 30; // Maximum swing angle in degrees
        const swingSpeed = 0.005; // Adjust for faster/slower swinging

        const animate = () => {
            angle = maxAngle * Math.sin(Date.now() * swingSpeed);
            logoElement.style.transform = `rotate(${angle}deg)`;
            requestAnimationFrame(animate);
        };

        animate();
    }, []);

    return (
        <div className="flex items-center justify-center h-screen overflow-hidden">
            <div className="w-24 h-24 logo-animation" style={{ transformOrigin: 'center center' }}>
                <img
                    src={logo}
                    alt="Loading"
                    className="w-full h-full object-contain"
                />
            </div>
        </div>
    );
};

export default LoadingPage;
