import React from 'react'
import { Helmet } from 'react-helmet';

function SEO({title, description, revisedDate, keywords, subject, author, url, newsKeywords, language}) {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name='description' content={description} />
            <meta name='subject' content={subject} />
            <meta name='keywords' content={keywords} />
            <meta name='language' content={language}/>
            <meta name='revised' content={revisedDate}/>
            <meta name='author' content={author}/>
            <meta name='url' content={url}/>

            <meta http-equiv='Cache-Control' content='no-cache'/>

            <meta name="news_keywords" content={newsKeywords}/>
        </Helmet>
    );
}

export default SEO