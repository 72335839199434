import React from "react";

const SingleProjectFooter = ({ singleArticles }) => {
    return (
        <div>
            <p className="outfit-medium text-[1.5rem] text-center pt-40">
                {singleArticles.fields.title}
            </p>
        </div>
    );
};

export default SingleProjectFooter;
