import PropTypes from "prop-types";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";

export default function CodeBlock({ language = "javascript", children }) {
    const code = Array.isArray(children) ? children.join("") : children;
    const trimmedCode = code.trimEnd();

    return (
        <SyntaxHighlighter
            language={language}
            style={atomDark}
            wrapLongLines
            showLineNumbers
            showInlineLineNumbers
        >
            {trimmedCode}
        </SyntaxHighlighter>
    );
}

CodeBlock.propTypes = {
    language: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
};
