import React, { useEffect, useState } from "react";
import client from "../../../client";
import { useParams } from "react-router-dom";
import ReactLenis from "lenis/react";

import FloatingBar from "../../../components/floating bar/FloatingBar";
import BadRequest from "../../maintenance/400Page";
import SEO from "../../../components/seo/SEO";
import SingleProjectHeader from "./singleProjectComponent/SingleProjectHeader";
import SingleProjectComponent from "../singleProjectGlobalComponent/SingleProjectComponent";
import SingleProjectFooter from "../singleProjectGlobalComponent/SingleProjectFooter";
import SingleProjectPublish from "../singleProjectGlobalComponent/SingleProjectPublish";
import LoadingPage from "../../../components/loading/loadingPage";
import SingleProjectDetails from "./singleProjectComponent/SingleProjectDetails";
import PageNotFound from "../../maintenance/404Page";

export function withRouter(Children) {
    return ({ match, ...props }) => {
        const updatedMatch = { params: useParams(), ...match };
        return <Children {...props} match={updatedMatch} />;
    };
}

function SingleProject({ match }) {
    const [singleArticles, setSingleArticles] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await client.getEntries({
                    content_type: "frontend",
                    "fields.slug": match?.params.slug,
                });
                setSingleArticles(response.items[0]);
            } catch (err) {
                setError(err);
                setIsLoading(false);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [match?.params.slug]);

    if (isLoading) return <LoadingPage />;

    if (error) return <BadRequest />;

    if(!singleArticles) return <PageNotFound/>

    return (
        <ReactLenis root>
            <SEO
                title={singleArticles.fields.title}
                subject="project"
                keywords={singleArticles.fields.keywords}
                language={singleArticles.sys.locale}
                revisedDate={singleArticles.sys.updatedAt}
                author={singleArticles.fields.postedBy}
                url={singleArticles.fields.url}
                newsKeywords={singleArticles.fields.keywords}
            />
            <FloatingBar position="bottom" />
            <div className="flex flex-col gap-40 pb-32 duration-300">
                <SingleProjectHeader singleArticles={singleArticles} />
                <SingleProjectDetails singleArticles={singleArticles}/>
                <SingleProjectPublish singleArticles={singleArticles} />
                <SingleProjectComponent singleArticles={singleArticles} />
                <SingleProjectFooter singleArticles={singleArticles} />
            </div>
        </ReactLenis>
    );
}

export default withRouter(SingleProject);
