import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Navbar({ position }) {
    const [showNavbar, setShowNavbar] = useState(true);
    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
        let timeout;
        if (showNavbar) {
            timeout = setTimeout(() => {
                setShowNavbar(false);
            }, 60000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [showNavbar]);

    const handleMouseEnter = () => {
        setShowNavbar(true);
        setShowDropdown(true);
    };

    const handleMouseLeave = () => {
        setShowNavbar(false);
        setShowDropdown(false);
    };

    return (
        <div className="fixed left-1/2 -translate-x-1/2 top-10 flex gap-2 bg-white/30 backdrop-blur-xl px-6 rounded-full z-20 shadow-md shadow-black/10 w-max">
            <Link
                to="/about"
                className="outfit group p-2.5 rounded-xl duration-100"
            >
                Home
            </Link>
            <div
                className="p-2.5 rounded-xl duration-100 relative group"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <p className="outfit cursor-default">Project</p>
                <div
                    className={`absolute left-1/2 -translate-x-1/2 pt-5 bg-transparent ${
                        showDropdown ? "visible" : "invisible"
                    }`}
                >
                    <div
                        className={`opacity-0 transform transition-all duration-300 ease-in-out ${
                            showDropdown
                                ? "opacity-100 translate-y-0 translate-x-0"
                                : ""
                        }`}
                    >
                        <ul
                            className="flex flex-col items-center space-y-2 whitespace-nowrap w-full"
                        >
                            <Link
                                to="/qa"
                                className={`bg-white hover:text-blue-600 shadow-md rounded-full py-2 px-4 outfit transition-opacity duration-200 ${
                                    showDropdown ? "opacity-100" : "opacity-0"
                                }`}
                            >
                                Quality Assurance
                            </Link>
                            <Link
                                to="/frontend"
                                className={`bg-white hover:text-blue-600 shadow-md rounded-full py-2 px-4 outfit transition-opacity duration-200 ${
                                    showDropdown ? "opacity-100" : "opacity-0"
                                }`}
                            >
                                Front End
                            </Link>
                            <Link
                                to="/uiux"
                                className={`bg-white hover:text-blue-600 shadow-md rounded-full py-2 px-4 outfit transition-opacity duration-200 ${
                                    showDropdown ? "opacity-100" : "opacity-0"
                                }`}
                            >
                                UI/UX
                            </Link>
                        </ul>
                    </div>
                </div>
            </div>
            <Link
                to="/insight"
                className="outfit group p-2.5 rounded-xl duration-100"
            >
                Insight
            </Link>
        </div>
    );
}
