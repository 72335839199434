import React from 'react';

function Max320() {
    return (
        <>
            <div className='flex flex-col justify-center align-middle gap-7 h-screen bg-[#fff] dark:bg-[#0d0c13] text-center tracking-[0.02rem] px-5 duration-300'>
                <p className='instruments-semibold text-slate-900 dark:text-slate-200 text-[18px] duration-300'>
                    Are you struggling to access the website?
                </p>
                <p className='instruments text-slate-500 dark:text-slate-400 text-[14px] leading-6 duration-300'>
                    If you're having trouble accessing our website, it could be
                    because your screen is too small. I require a minimum screen
                    width of 320px for a seamless browsing experience.
                </p>
            </div>
        </>
    );
}

export default Max320;
