import React from "react";
import { GithubLogo } from "phosphor-react";

const SingleProjectToolsPublish = ({ singleArticles }) => {
    return (
        <div className="flex flex-wrap justify-between gap-20 px-5 md:px-10 lg:px-32 xl:px-80">
            {
                singleArticles.fields.tools && (
                    <section className="flex flex-col gap-5">
                        <p className="outfit text-gray-500]">
                            Testing Tools
                        </p>
                        <div className="flex gap-10 lg:gap-20 mx-auto">
                            {singleArticles.fields.tools.map((tool, i) => (
                                <p key={i} data-aos="fade-up" className="outfit-medium">
                                    {tool.fields.toolName}
                                </p>
                            ))}
                        </div>
                    </section>
                )
            }
            {
                singleArticles.fields.repositoryUrl && (
                    <section className="flex flex-col gap-5">
                        <p className="outfit text-gray-500">
                            Check the repository
                        </p>
                        <a
                            href={singleArticles.fields.repositoryUrl}
                            target="_blank"
                            rel="noreferrer"
                            data-aos="fade-up"
                            className="group flex items-center gap-2 outfit-medium w-max cursor-pointer"
                        >
                            <GithubLogo
                                size={24}
                                className="group-hover:rotate-45 duration-200"
                            />
                            {singleArticles.fields.linkname}
                        </a>
                    </section>
                )
            }
        </div>
    );
};

export default SingleProjectToolsPublish;
