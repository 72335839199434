import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App'
import reportWebVitals from './reportWebVitals'
import Max320 from './components/responsive page/Max320'
// import ThemeTrigger from './components/theme/ThemeTrigger';
import { CookiesProvider } from 'react-cookie';

const root = ReactDOM.createRoot(document.getElementById('root'));

const MainContent = ({ screenWidth }) => {
  if (screenWidth < 320) {
    return <Max320 />;
  }

  return (
    <App />
  );
};

const AppWrapper = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <React.StrictMode>
      <CookiesProvider>
        <MainContent screenWidth={screenWidth} />
        {/* <ThemeTrigger /> */}
      </CookiesProvider>
    </React.StrictMode>
  );
};

root.render(<AppWrapper />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


