import React from 'react';

function WebsiteStatus(props) {
    return (
        <>
            {props.show === 'developing' ? (
                <div className='fixed bottom-0 w-full z-50 bg-yellow-400 p-2'>
                    <p className='instruments text-[14px] text-center'>
                        I am actively developing my website to make it better,
                        but please note that errors or inaccuracies may occur
                        during this time.
                    </p>
                </div>
            ) : props.show === 'fixing' ? (
                <div className='fixed bottom-0 w-full z-50 bg-yellow-400 p-2'>
                    <p className='instruments text-[14px] text-center'>
                    Progressing Enhancements! The website is undergoing
                    fine-tuning while it remains accessible. Temporary effects
                    on certain sections are expected.
                    </p>
                </div>
            ) : null}
        </>
    );
}

export default WebsiteStatus;
