import React from 'react'
import Index from './Index'

function Offline() {
    return (
        <>
            <Index
                title="No internet connection found"
                page="offline"
                description="Kindly check your internet connection and try to reconnect."
                showBackbutton={false}/>
        </>
    )
}

export default Offline