import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const SingleProjectHeader = ({ singleArticles }) => {
    React.useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div className="flex flex-col gap-10 lg:gap-20 pt-40">
            <section className="flex flex-col lg:flex-row justify-between gap-5 px-5 md:px-10 xl:px-24">
                <div data-aos="fade-right" className="flex flex-col">
                    <div className="flex flex-wrap gap-x-3">
                        {singleArticles.fields.tags.map((tag, i) => (
                            <p
                                key={i}
                                className="text-gray-500 rounded-full pr-2 py-1 outfit-medium text-[14px] uppercase tracking-wider"
                            >
                                {tag}
                            </p>
                        ))}
                    </div>
                    <p className="outfit-semibold text-[24px] md:text-[39px] lg:text-[47px] w-full lg:w-4/5">
                        {singleArticles.fields.title}
                    </p>
                </div>
                <div
                    data-aos="fade-left"
                    className="flex flex-col items-end gap-5 w-full lg:w-[40%]"
                >
                    <p className="outfit text-[1.1rem] text-left lg:text-right leading-7 duration-300">
                        {singleArticles.fields.outline}
                    </p>
                    <div className="bg-gray-200 w-64 h-px"></div>
                </div>
            </section>
            {singleArticles.fields.headerMockup && (
                <section className="flex justify-center px-5 md:px-10">
                    <img
                        src={
                            "https:" +
                            singleArticles.fields.headerMockup.fields.file.url
                        }
                        alt=""
                        data-aos="fade-up"
                        className="w-full lg:w-3/4 h-full shadow-2xl shadow-slate-100 rounded-[20px]"
                    />
                </section>
            )}
        </div>
    );
};

export default SingleProjectHeader;
